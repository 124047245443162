import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	ClickAwayListener,
	Grow,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Paper,
	Popper,
	Tab,
	Tabs,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
	OrgIcon,
	EditUserIcon,
	TeamUserIcon,
	BottomArrowIcon,
	ManageOrgIcon,
	PerformanceDevelopmentIcon,
	PerformanceAssessmentIcon,
} from '../../config/svg/CommonSvgIcon';
import { checkPermission } from '../../config/rolePermission';
import {
	supportModulePerformAssessment,
	viewOrganizationManagementPage,
	viewRoleManagement,
	viewUserManagementPage,
	supportModulePerformDevelopment,
	supportModuleAlignOkr,
	supportModuleAlignGoals,
	supportModuleLetterGeneration,
} from './AdminConstant';
import { PopperMenu } from './CommonComponent/PopperMenu';
import {
	AlignIcon,
	EngageIcon,
	LetterIcon,
	OrgChartIcon,
	PerformanceIcon,
	RewardsIcon,
	StrategicObjIcon,
} from '../../config/svg/CommonSvg';
import { useSelector, useDispatch } from 'react-redux';
import { currentOrgTabs, currentRolePermissionsTabs, currentSettingTabs } from '../../action/common';
import { checkSupportPermissionType } from '../../config/utils';
import { GoalCycleIcon, GoalReviewIcon } from '../../config/svg/MyGoalSvg';
import { getLocalStorageItem } from '../../services/StorageService';

export const AdminTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`admin-tab-${index}`}
			aria-labelledby={`admin-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

AdminTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `admin-tabpanel-${index}`,
		'aria-controls': `admin-tabpanel-${index}`,
	};
}

export const AdminListingTabs: React.FC<any> = (props) => {
	const {
		handleTabChange = {},
		tabSelected = 0,
		getPermissionResult,
		setOrgDropdownSelected,
		orgDropdownSelected,
		handleTabMenuChange,
		setDefaultOkrOpen,
		setGoalFormOpen,
		setGoalReviewOpen,
	} = props;
	const { t } = useTranslation();
	const divRef = React.useRef<any>();
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<any>({ show: false, type: null });
	const dispatch = useDispatch();
	const userDetail = JSON.parse(getLocalStorageItem('userDetail') || '');
	const [isLgAllowed, setIsLgAllowed] = useState<boolean>(false);

	useEffect(() => {
		setIsLgAllowed(JSON.parse(getLocalStorageItem('userDetail') || '').isLGAllowed);
	}, []);

	const handleClick = (event: any, tabId: any) => {
		event.stopPropagation();
		setOpen({ show: true, type: tabId });
		setAnchorEl(event.currentTarget);
		if (tabSelected === 1) {
			dispatch(currentRolePermissionsTabs(null));
		}
		if (tabSelected === 2) {
			dispatch(currentSettingTabs(null));
		}
		if (tabSelected === 3) {
			dispatch(currentOrgTabs(null));
		}
	};
	const handleClose = (event: any) => {
		setAnchorEl(null);
		setOpen({ show: false, type: null });
		// setDefaultOkrOpen(false);
	};
	const { currentRolePermissionsTab, currentOrgTab, currentSettingTab } = useSelector(
		(state: any) => state.commonReducer
	);
	return (
		<>
			<Tabs className='okr-tabs' value={tabSelected} onChange={handleTabChange} aria-label='Admin Tabs'>
				{checkPermission(getPermissionResult?.employeePermissions, viewUserManagementPage) && (
					<Tab
						//icon={<TeamUserIcon />}
						label={t('manageUserLabel')}
						value={0}
						{...a11yProps(0)}
					/>
				)}
				{checkPermission(getPermissionResult?.employeePermissions, viewRoleManagement) && (
					<Tab
						//icon={<EditUserIcon />}
						className='arrow-tab'
						label={
							<>
								<span>{t('rolePermissionLabel')}</span>
								<span className='arrowIcon' id='role-dropdown-icon'>
									<BottomArrowIcon />
								</span>
							</>
						}
						value={1}
						onClick={(e) => handleClick(e, 1)}
						{...a11yProps(1)}
					/>
				)}
				{checkPermission(getPermissionResult?.employeePermissions, viewOrganizationManagementPage) && (
					<Tab
						//icon={<OrgChartIcon />}
						className='arrow-tab'
						label={
							<>
								<span>{t('organizationLabel')}</span>
								<span className='arrowIcon' id='org-dropdown-icon'>
									<BottomArrowIcon />
								</span>
							</>
						}
						value={2}
						onClick={(e) => handleClick(e, 2)}
						{...a11yProps(2)}
					/>
				)}
				<Tab
					className='arrow-tab'
					label={
						<>
							<span>{t('settingsLabel')}</span>
							<span className='arrowIcon' id='setting-dropdown-icon'>
								<BottomArrowIcon />
							</span>
						</>
					}
					value={3}
					{...a11yProps(3)}
					onClick={(e) => handleClick(e, 3)}
				/>
				{/* {checkPermission(getPermissionResult?.employeePermissions, viewOrganizationManagementPage) && (
					<Tab icon={<OrgIcon />} label={'Org Chart POC'} value={3} {...a11yProps(3)} />
				)} */}
			</Tabs>
			{open.show && open.type === 1 ? (
				<Popper
					className={'popper-menu tabs-dropdown-menu'}
					open={open.show ? true : false}
					anchorEl={anchorEl}
					placement={'bottom-end'}
					modifiers={{
						preventOverflow: {
							enabled: false,
							boundariesElement: window,
						},

						flip: {
							enabled: false, // true by default
						},
					}}
					transition
				>
					{({ TransitionProps }) => (
						<Grow {...TransitionProps}>
							<Paper>
								<ClickAwayListener
									onClickAway={(e) => {
										handleClose(e);
									}}
								>
									<Box className='popper-menu-inner'>
										<List>
											<ListItem
												className={currentRolePermissionsTab === 'systemRolesPermissions' ? 'active' : ''}
												id='role-permissions-link'
												onClick={(e) => {
													dispatch(currentRolePermissionsTabs('systemRolesPermissions'));
													handleClose(e);
													handleTabMenuChange(e, 1);
												}}
											>
												<ListItemAvatar>
													<StrategicObjIcon />
												</ListItemAvatar>
												<ListItemText primary={t('systemRolePermissionLabel')} />
											</ListItem>
											<ListItem
												className={currentRolePermissionsTab === 'additionalPermissions' ? 'active' : ''}
												id='additional-permissions-link'
												onClick={(e) => {
													dispatch(currentRolePermissionsTabs('additionalPermissions'));
													handleClose(e);
													handleTabMenuChange(e, 1);
												}}
											>
												<ListItemAvatar>
													<StrategicObjIcon />
												</ListItemAvatar>
												<ListItemText primary={t('additionalPermissions')} />
											</ListItem>
										</List>
									</Box>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			) : null}
			{open.show && open.type === 2 ? (
				<Popper
					className={'popper-menu tabs-dropdown-menu'}
					open={open.show ? true : false}
					anchorEl={anchorEl}
					placement={'bottom-end'}
					modifiers={{
						preventOverflow: {
							enabled: false,
							boundariesElement: window,
						},

						flip: {
							enabled: false, // true by default
						},
					}}
					transition
				>
					{({ TransitionProps }) => (
						<Grow {...TransitionProps}>
							<Paper>
								<ClickAwayListener
									onClickAway={(e) => {
										handleClose(e);
									}}
								>
									<Box className='popper-menu-inner'>
										<List>
											<ListItem
												className={currentOrgTab === 0 ? 'active' : ''}
												id='org-chart'
												onClick={(e) => {
													dispatch(currentOrgTabs(0));
													handleClose(e);
													handleTabMenuChange(e, 2);
												}}
											>
												<ListItemAvatar>
													<OrgIcon />
												</ListItemAvatar>
												<ListItemText primary={t('orgStructure')} />
											</ListItem>
											<ListItem
												className={currentOrgTab === 1 ? 'active' : ''}
												id='manage-org'
												onClick={(e) => {
													dispatch(currentOrgTabs(1));
													handleClose(e);
													handleTabMenuChange(e, 2);
												}}
											>
												<ListItemAvatar>
													<ManageOrgIcon />
												</ListItemAvatar>
												<ListItemText primary={t('manageOrganizationLabel')} />
											</ListItem>
										</List>
									</Box>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			) : null}
			{open.show && open.type === 3 ? (
				<Popper
					className={'popper-menu tabs-dropdown-menu'}
					open={open.show ? true : false}
					anchorEl={anchorEl}
					placement={'bottom-end'}
					modifiers={{
						preventOverflow: {
							enabled: false,
							boundariesElement: window,
						},

						flip: {
							enabled: false, // true by default
						},
					}}
					transition
				>
					{({ TransitionProps }) => (
						<Grow {...TransitionProps}>
							<Paper>
								<ClickAwayListener
									onClickAway={(e) => {
										handleClose(e);
									}}
								>
									<Box className='popper-menu-inner'>
										<List>
											{checkSupportPermissionType(supportModuleAlignOkr) ? (
												<ListItem
													className={currentSettingTab === 'strategicObj' ? 'active' : ''}
													id='strategic-obj-link'
													onClick={(e) => {
														dispatch(currentSettingTabs('strategicObj'));
														handleClose(e);
														handleTabMenuChange(e, 3);
													}}
												>
													<ListItemAvatar>
														<StrategicObjIcon />
													</ListItemAvatar>
													<ListItemText primary={t('strategicObjLabel')} />
												</ListItem>
											) : (
												<></>
											)}
											{checkSupportPermissionType(supportModuleAlignOkr) ? (
												<ListItem
													className={currentSettingTab === 'align' ? 'active' : ''}
													id='align-link'
													onClick={(e) => {
														setDefaultOkrOpen(false);
														dispatch(currentSettingTabs('align'));
														handleClose(e);
														handleTabMenuChange(e, 3);
													}}
												>
													<ListItemAvatar>
														<AlignIcon />
													</ListItemAvatar>
													<ListItemText primary={t('alignOkrsLabel')} />
												</ListItem>
											) : (
												<></>
											)}
											{/* 	{checkSupportPermissionType(supportModuleAlignGoals) ? (
												<> */}
											<ListItem
												className={currentSettingTab === 'alignGoals' ? 'active' : ''}
												id='align-goals-link'
												onClick={(e) => {
													setGoalFormOpen({ open: false, data: {} });
													dispatch(currentSettingTabs('alignGoals'));
													handleClose(e);
													handleTabMenuChange(e, 3);
												}}
											>
												<ListItemAvatar>
													<GoalCycleIcon />
												</ListItemAvatar>
												<ListItemText primary={t('alignGoalsLabel')} />
											</ListItem>
											<ListItem
												className={currentSettingTab === 'goalReviews' ? 'active' : ''}
												id='align-goals-link'
												onClick={(e) => {
													setGoalReviewOpen({ open: false, data: {} });
													dispatch(currentSettingTabs('goalReviews'));
													handleClose(e);
													handleTabMenuChange(e, 3);
												}}
											>
												<ListItemAvatar>
													<GoalReviewIcon />
												</ListItemAvatar>
												<ListItemText primary={t('goalReviewsLevel')} />
											</ListItem>
											{/*</>
											 ) : (
												<></>
											)} */}
											<ListItem
												className={currentSettingTab === 'engage' ? 'active' : ''}
												id='engage-link'
												onClick={(e) => {
													dispatch(currentSettingTabs('engage'));
													handleClose(e);
													handleTabMenuChange(e, 3);
												}}
											>
												<ListItemAvatar>
													<EngageIcon />
												</ListItemAvatar>
												<ListItemText primary={t('engageLabel')} />
											</ListItem>

											{checkSupportPermissionType(supportModulePerformAssessment) ? (
												<ListItem
													className={currentSettingTab === 'performReview' ? 'active' : ''}
													id='perform-assessment-link'
													onClick={(e) => {
														dispatch(currentSettingTabs('performReview'));
														handleClose(e);
														handleTabMenuChange(e, 3);
													}}
												>
													<ListItemAvatar>
														<PerformanceAssessmentIcon />
													</ListItemAvatar>
													<ListItemText primary={t('performanceAssessment')} />
												</ListItem>
											) : (
												<></>
											)}

											{checkSupportPermissionType(supportModulePerformDevelopment) ? (
												<ListItem
													className={currentSettingTab === 'adminPerformanceDevlopment' ? 'active' : ''}
													id='performance-development-link'
													onClick={(e) => {
														dispatch(currentSettingTabs('adminPerformanceDevlopment'));
														handleClose(e);
														handleTabMenuChange(e, 3);
													}}
												>
													<ListItemAvatar>
														<PerformanceDevelopmentIcon />
													</ListItemAvatar>
													<ListItemText primary={t('prsDevelopment')} />
												</ListItem>
											) : (
												<></>
											)}

											<ListItem
												className={currentSettingTab === 'recognition' ? 'active' : ''}
												id='recognition-link'
												onClick={(e) => {
													dispatch(currentSettingTabs('recognition'));
													handleClose(e);
													handleTabMenuChange(e, 3);
												}}
											>
												<ListItemAvatar>
													<RewardsIcon />
												</ListItemAvatar>
												<ListItemText primary={t('recognition')} />
											</ListItem>
											{checkSupportPermissionType(supportModuleLetterGeneration) &&
											userDetail.roleId &&
											userDetail.roleId === 1 ? (
												<ListItem
													className={currentSettingTab === 'lettergen' ? 'active' : ''}
													id='lettergen-link'
													onClick={(e) => {
														dispatch(currentSettingTabs('lettergen'));
														handleClose(e);
														handleTabMenuChange(e, 3);
													}}
												>
													<ListItemAvatar>
														<LetterIcon />
													</ListItemAvatar>
													<ListItemText primary={t('letterGenTitle')} />
												</ListItem>
											) : (
												<></>
											)}
										</List>
									</Box>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			) : null}
		</>
	);
};
