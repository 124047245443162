import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorIcon } from '../../../../config/svg/formElementIcons';
import { getUserDetails } from '../../../../config/utils';
import { MultiSelect } from '../../../Admin/CommonComponent/MultiSelect';
import { NoSearchRecord } from '../../../Admin/CommonComponent/NoSearchRecord';
import { useDispatch } from 'react-redux';
import { recognitionSearchWithTeam } from '../../../../action/search';
import { getSystemPermissionSearch } from '../../../../action/adminSettings';
import { Box } from '@material-ui/core';

export const PermissionSearch = (props: any) => {
	const {
		formErrors,
		elementKey = '',
		onInputFocus,
		customSearch = false,
		searchCustomEmployee,
		fieldLabel = null,
		fieldId = null,
		performSearchOnFocus = false,
	} = props;
	const { t } = useTranslation();
	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const dispatch = useDispatch();

	const [emptyRecords, setEmptyRecords] = useState<any>({
		reportingTo: false,
		team: false,
		roleId: false,
	});

	let userDetail = getUserDetails();

	const getTeamData = (data: any) => {
		return dispatch(getSystemPermissionSearch(data));
	};
	return (
		<Box className={`performance-search-box ${formErrors[elementKey] ? 'helperText-bottom' : ''}`}>
			<MultiSelect
				key={'reportingTo'}
				id='permission-to-dropdown'
				selectedOptions={props.selectedUser}
				optionsList={reportingToOptions}
				onSelectOption={(value: any) => {
					props.setSelectedUser(value);
					setReportingToOptions([]);
					setEmptyRecords({ ...emptyRecords, reportingTo: false });
				}}
				blurCalled={(value: any) => {
					setReportingToOptions([]);
				}}
				setEmptyRecords={setEmptyRecords}
				blurInputOnSelect={true}
				isMulti={true}
				customFilter={() => {}}
				setOptionsList={setReportingToOptions}
				isLoadMore={true}
				placeHolder={t('selectLabel')}
				noOptionsMessage={<NoSearchRecord />}
				showNoOptions={emptyRecords.reportingTo === true}
				fetchAsyncData={true}
				isSingleSelection={false}
				closeMenuOnSelect={true}
				labelTemplate={'avatarLabel'}
				selectClassName={`select-search-dropdown permission-to-dropdown ${
					props.selectedUser && props.selectedUser.length === 0 && props.focussed ? 'error' : ''
				}`}
				selectClassNamePrefix={'react-select'}
				error={(formErrors[elementKey] && formErrors[elementKey]) || ''}
				helperText={
					formErrors[elementKey] &&
					formErrors[elementKey] && (
						<>
							<ErrorIcon />
							{(formErrors[elementKey] && formErrors[elementKey]) || ''}
						</>
					)
				}
				onInputFocus={onInputFocus}
				disabled={props.disabled}
				performSearch={customSearch ? searchCustomEmployee : getTeamData}
				hideLoggedIn={true}
				loggedInUserIds={[userDetail.employeeId]}
				fieldLabel={fieldLabel}
				fieldId={fieldId}
				performSearchOnFocus={performSearchOnFocus}
			/>
		</Box>
	);
};
