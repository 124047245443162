import React, { Fragment, useRef, useEffect, useState } from 'react';
import { Box, FormControlLabel, FormLabel, Link, Switch, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BackArrow } from '../../../../../../config/svg/GlobalSvg';
import '../../../../../../styles/pages/admin/settings/default-okr/createDefaultOkr.scss';
import { CalendarIcon, NameRenameIcon } from '../../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../../Common/OKRButton';
import { ErrorIcon } from '../../../../../../config/svg/formElementIcons';
import ReactDateRangePicker from '../../../../../Common/ReactDateRangePicker';
import { checkUnSavePopUpVisible, formatFeedbackDate, getRemainingCharacter } from '../../../../../../config/utils';
import { EnumPerformanceGoalCycleStatus, Enums } from '../../../../../../config/enums';
import { AssignUsers } from '../../AssignUsers';
import { useDispatch } from 'react-redux';
import AlertDialog from '../../../../../Common/Dialog';
import {
	addGoalCycle,
	updateGoalCycle,
	goalCycleDetails,
	setHighlightGoalCycleId,
	isTimeframeOverlap,
} from '../../../../../../action/adminSettings';
import { useSnackbar } from 'notistack';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import LinearLoader from '../../../../../Common/Loader';
import exportFromJSON from 'export-from-json';

export const CreateCycleForm: React.FC<any> = (props: any) => {
	const { loader, setLoader, setTabSelected, resetSearchAndPagination, setGoalCycleOpen, goalCycleOpen } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const moment = extendMoment(Moment);

	const ref = useRef<any>(null);
	const goalCycleObj = {
		performanceGoalCycleId: Enums.ZERO,
		name: '',
		startDate: null,
		endDate: null,
		settingDurationStartDate: null,
		settingDurationEndDate: null,
		isAssignToAllEmployee: true,
		status: 1,
		assignedEmployeeIds: [],
	};

	const staticErrorObj = {
		error: '',
		helperText: '',
		type: '',
	};

	const [goalCycleModalProps, setGoalCycleModalProps] = useState<any>({
		open: false,
		event: null,
		message: '',
		details: '',
		type: '',
	});
	const minDateValue = new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 18);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 12);

	const [goalCycleData, setGoalCycleData] = useState(goalCycleObj);
	const [isGoalCycleFormEdited, setIsGoalCycleFormEdited] = useState<boolean>(false);
	const [selectedUsersOptions, setSelectedUsersOptions] = useState<any>([]);
	const [goalCycleNameError, setGoalCycleNameError] = useState<any>(staticErrorObj);
	const [goalCycleTimeframeError, setGoalCycleTimeframeError] = useState<any>(staticErrorObj);
	const [goalCycleDurationError, setGoalCycleDurationError] = useState<any>(staticErrorObj);
	const [goalCycleAssignedUserError, setGoalCycleAssignedUserError] = useState<any>(staticErrorObj);
	const [selectedTimeframeStartDateRange, setSelectedTimeframeStartDateRange] = useState<any>(null);
	const [selectedTimeframeEndDateRange, setSelectedTimeframeEndDateRange] = useState<any>(null);
	const [selectedDurationStartDateRange, setSelectedDurationStartDateRange] = useState<any>(null);
	const [selectedDurationEndDateRange, setSelectedDurationEndDateRange] = useState<any>(null);
	const [downloadErrorUsers, setDownloadErrorUsers] = useState([]);

	useEffect(() => {
		if (goalCycleOpen && goalCycleOpen.data && Object.keys(goalCycleOpen.data).length > 0) {
			getGoalCycleDetails(goalCycleOpen.data && goalCycleOpen.data?.performanceGoalCycleId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalCycleOpen]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGoalCycleFormEdited]);

	const handleClickOutside = (event: any) => {
		const datePickerPopup = document.getElementsByClassName('DateRangePicker');

		if (event?.currentTarget?.activeElement?.id === 'download-error-btn') {
			//
		} else {
			if (
				ref.current &&
				!ref.current?.contains(event.target) &&
				checkUnSavePopUpVisible(event) &&
				isGoalCycleFormEdited &&
				!datePickerPopup.length
			) {
				const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
				if (dialogDiv.length === 0) {
					event.preventDefault();
					event.stopPropagation();
					setGoalCycleModalProps({ open: true, event: event, message: t('unSavedItemAlert'), details: '', type: '' });
				}
			} else {
				//
			}
		}
	};

	const getGoalCycleDetails = async (performanceGoalCycleId: number) => {
		try {
			setLoader(true);
			if (performanceGoalCycleId && performanceGoalCycleId > 0) {
				const requestParam = `performanceGoalCycleId=${performanceGoalCycleId}`;
				const response: any = await dispatch(goalCycleDetails(requestParam));
				if (response) {
					if (response && response.data && response.data.status === 200) {
						const { entity } = response.data;
						if (entity) {
							setSelectedUsersOptions(entity.assignedUsers || []);
							setSelectedTimeframeStartDateRange(entity.startDate ? moment(new Date(entity.startDate)) : null);
							setSelectedTimeframeEndDateRange(entity.endDate ? moment(new Date(entity.endDate)) : null);
							setSelectedDurationStartDateRange(
								entity.settingDurationStartDate ? moment(new Date(entity.settingDurationStartDate)) : null
							);
							setSelectedDurationEndDateRange(
								entity.settingDurationEndDate ? moment(new Date(entity.settingDurationEndDate)) : null
							);
							setGoalCycleData({
								...goalCycleData,
								...entity,
								assignedUsersHistory: entity.assignedUsers || [],
							});
						} else {
							setGoalCycleData(goalCycleObj);
						}
						setLoader(false);
					} else {
						setLoader(false);
						setGoalCycleData(goalCycleObj);
					}
				}
			} else {
				setLoader(false);
				setGoalCycleData(goalCycleObj);
			}
		} catch (e) {
			setLoader(false);
			console.error(e);
		}
	};

	const handleGoalCycleBackButtonOnClick = () => {
		if (isGoalCycleFormEdited) {
			setGoalCycleModalProps({ open: true, event: null, message: t('unSavedItemAlert'), details: '', type: '' });
		} else {
			goalCycleBackButtonClick();
		}
	};

	const handleCloseGoalCycleModal = async (e: any, type: any) => {
		e.preventDefault();
		if (goalCycleModalProps?.type === 'TimeFrame') {
			if (type === 1) {
				if (goalCycleModalProps?.event) {
					setGoalCycleData(goalCycleObj);
					setIsGoalCycleFormEdited(false);
					const { target } = goalCycleModalProps.event;
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				} else {
					handleGoalCycleForm(goalCycleModalProps?.details, goalCycleOpen.type);
				}
			} else {
				//type=== 2 cancel
			}
		} else if (goalCycleModalProps?.type === 'OverlapCycleUsers') {
			//
		} else {
			if (type === 1) {
				if (goalCycleModalProps?.event) {
					setGoalCycleData(goalCycleObj);
					setIsGoalCycleFormEdited(false);
					const { target } = goalCycleModalProps.event;
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				} else {
					goalCycleBackButtonClick();
				}
			} else {
				if (goalCycleModalProps.type === 'goalCycleCreate') {
					setIsGoalCycleFormEdited(true);
				}
			}
		}

		setGoalCycleModalProps({ open: false, event: null, message: '', details: '', type: '' });
	};

	const goalCycleBackButtonClick = () => {
		resetSearchAndPagination();
		setGoalCycleOpen({ open: false, data: {}, type: 'Add' });
		setGoalCycleData(goalCycleObj);
		setTabSelected(0);
		setGoalCycleNameError(staticErrorObj);
		setGoalCycleTimeframeError(staticErrorObj);
		setGoalCycleDurationError(staticErrorObj);
		setGoalCycleAssignedUserError(staticErrorObj);
	};

	const handleGoalCycleValueChange = (event: any) => {
		const { value, name, checked } = event?.target;
		if (name === 'name' && value) {
			setGoalCycleNameError({
				error: '',
				helperText: '',
				type: '',
			});

			setIsGoalCycleFormEdited(true);
			setGoalCycleData({ ...goalCycleData, [name]: value });
		} else {
			setGoalCycleData({ ...goalCycleData, [name]: '' });
		}
	};

	const saveGoalCycleAsDraft = (e: any) => {
		submitGoalCycleCreateRequest(1);
	};

	const saveGoalCycleAsSubmit = (e: any) => {
		submitGoalCycleCreateRequest(2);
	};

	const validateForm = (statusType: number) => {
		let returnFlag: boolean = true;
		if (!goalCycleData?.name) {
			setGoalCycleNameError({
				error: t('goalCycleNameErrorMsg'),
				helperText: t('goalCycleNameErrorMsg'),
				type: 'name',
			});
			returnFlag = false;
		}
		if (statusType === 2) {
			if (!selectedTimeframeStartDateRange || !selectedTimeframeEndDateRange) {
				setGoalCycleTimeframeError({
					error: t('goalCycleTimeframeErrorMsg'),
					helperText: t('goalCycleTimeframeErrorMsg'),
					type: 'timeframeDate',
				});
				returnFlag = false;
			}
			if (!selectedDurationStartDateRange || !selectedDurationEndDateRange) {
				setGoalCycleDurationError({
					error: t('goalCycleDurationErrorMsg'),
					helperText: t('goalCycleDurationErrorMsg'),
					type: 'settingDurationDate',
				});
				returnFlag = false;
			}
			if (
				goalCycleData?.status !== EnumPerformanceGoalCycleStatus.Completed &&
				selectedUsersOptions &&
				selectedUsersOptions.length <= 0
			) {
				setGoalCycleAssignedUserError({
					error: t('goalCycleAssignUserErrorMsg'),
					helperText: t('goalCycleAssignUserErrorMsg'),
					type: 'assignedUser',
				});
				returnFlag = false;
			}
		}
		return returnFlag;
	};

	const submitGoalCycleCreateRequest = async (statusType: number) => {
		if (validateForm(statusType)) {
			setIsGoalCycleFormEdited(false);
			setGoalCycleNameError(staticErrorObj);
			setGoalCycleTimeframeError(staticErrorObj);
			setGoalCycleDurationError(staticErrorObj);
			setGoalCycleAssignedUserError(staticErrorObj);

			const startDate = selectedTimeframeStartDateRange?._d || selectedTimeframeStartDateRange;
			const endDate = selectedTimeframeEndDateRange?._d || selectedTimeframeEndDateRange;
			const settingDurationStartDate = selectedDurationStartDateRange?._d || selectedDurationStartDateRange;
			const settingDurationEndDate = selectedDurationEndDateRange?._d || selectedDurationEndDateRange;

			const requestPayload: any = {
				performanceGoalCycleId: goalCycleData?.performanceGoalCycleId || Enums.ZERO,
				name: goalCycleData?.name ? goalCycleData.name.trim() : '',
				startDate: startDate ? formatFeedbackDate(startDate) : null,
				endDate: endDate ? formatFeedbackDate(endDate) : null,
				settingDurationStartDate: settingDurationStartDate ? formatFeedbackDate(settingDurationStartDate) : null,
				settingDurationEndDate: settingDurationEndDate ? formatFeedbackDate(settingDurationEndDate) : null,
				isAssignToAllEmployee: false,
				status: statusType,
				assignedUsers: selectedUsersOptions,
			};
			if (statusType === 1) {
				//timeframe overlap not check for draft
				handleGoalCycleForm(requestPayload, goalCycleOpen.type);
			} else {
				handleTimeframeOverlap(requestPayload, goalCycleOpen.type);
			}
		} else {
			window.scrollTo(0, 0);
		}
	};
	const handleTimeframeOverlap = async (requestPayload: any, type: string) => {
		const updatedRequestPayload = { ...requestPayload };
		//check for timeframeOverlap
		const response: any = await dispatch(
			isTimeframeOverlap(
				`startDate=${updatedRequestPayload.startDate}&endDate=${updatedRequestPayload.endDate}&performanceGoalCycleId=${updatedRequestPayload.performanceGoalCycleId}`
			)
		);
		if (response) {
			if (response?.data && response?.data?.status === 200) {
				const { entity } = response.data;
				if (entity) {
					setGoalCycleModalProps({
						open: true,
						event: null,
						message: t('timeframeOverlapMessage'),
						details: requestPayload,
						type: 'TimeFrame',
					});
				} else {
					handleGoalCycleForm(requestPayload, goalCycleOpen.type);
				}
			} else {
				handleGoalCycleForm(requestPayload, goalCycleOpen.type);
			}
		}
	};
	const handleDownloadUserList = async () => {
		const updatedErrorLogMessage = downloadErrorUsers.map((item: any) => {
			return {
				'Record Row & Name': item.key, // Replace 'key' with 'Record Row & Name'
				'Error Description': item.value, // Replace 'value' with 'Error Description'
			};
		});
		exportFromJSON({ data: updatedErrorLogMessage, fileName: 'error_report', exportType: 'csv' });
	};

	const handleGoalCycleForm = async (requestPayload: any, type: string) => {
		setLoader(true);
		const updatedRequestPayload = { ...requestPayload };
		let response: any = null;
		if (type === 'Edit') {
			response = await dispatch(updateGoalCycle(updatedRequestPayload));
		} else {
			response = await dispatch(addGoalCycle(updatedRequestPayload));
		}
		setLoader(false);
		if (response) {
			if (response?.data && response?.data?.status === 200) {
				const { messageList, isSuccess, entity } = response.data;
				if (isSuccess) {
					dispatch(setHighlightGoalCycleId(entity));
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(
						messages && messages.length > 0
							? messages
							: `Goal cycle ${
									type === 'Add' && updatedRequestPayload.status === 2
										? 'created'
										: type === 'Edit'
										? 'updated'
										: 'created'
							  } successfully`,
						{
							variant: 'success',
							autoHideDuration: 3000,
						}
					);
					//setLoader(true);
					goalCycleBackButtonClick();
				} else {
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Error occurred while adding performance review', {
						variant: 'error',
						autoHideDuration: 3000,
					});
					window.scrollTo(0, 0);
				}
			} else {
				if (response?.data && response?.data?.status === 400) {
					const { messageList, isSuccess, entity } = response.data;
					if (entity !== null) {
						const { errors } = entity;
						setDownloadErrorUsers(errors);
						setGoalCycleModalProps({
							open: true,
							event: null,
							message: t('downloadOverlappingUsersMessage'),
							details: requestPayload,
							type: 'OverlapCycleUsers',
							showDownload: true,
						});
					} else {
						const { messageList } = response.data;
						const keys = Object.keys(messageList);
						const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
						enqueueSnackbar(messages || 'Error occurred while adding performance review', {
							variant: 'error',
							autoHideDuration: 3000,
						});
						window.scrollTo(0, 0);
					}
				} else {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					enqueueSnackbar(messages || 'Error occurred while adding performance review', {
						variant: 'error',
						autoHideDuration: 3000,
					});
					window.scrollTo(0, 0);
				}
			}
		}
	};

	return (
		<>
			<Box className='create-cycle-form'>
				<div ref={ref}>
					<Box className='admin-tabpanel-inner'>
						<Box className='admin-tabpanel-head'>
							<Box className='back-to-main' onClick={handleGoalCycleBackButtonOnClick}>
								<Typography variant='body2'>
									<Link id='back-btn'>
										<BackArrow /> {t('back')}
									</Link>
								</Typography>
							</Box>
							<Typography variant='h2'>{t('goalCycleLable')}</Typography>
						</Box>
					</Box>
					<Box className='full-rectangular-card create-cycle-form-card'>
						<Box className='add-cycle-form-wrapper'>
							<Box className='add-cycle-form-fields'>
								<Box
									className={`add-cycle-name-field ${
										goalCycleNameError?.type === 'name' && goalCycleNameError?.helperText ? 'custom-error-field' : ''
									}`}
								>
									<TextField
										id='name'
										fullWidth
										className='helperText-bottom'
										placeholder={t('goalCycleNamePlaceholder')}
										value={goalCycleData?.name || ''}
										label={
											<>
												<NameRenameIcon /> {t('nameLabel')}
												<i style={{ color: 'red' }}>*</i>
											</>
										}
										name='name'
										onChange={handleGoalCycleValueChange}
										inputProps={{ maxLength: 50 }}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											'aria-label': t('nameLabel'),
											disableUnderline: true,
											disabled: goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed,
										}}
										error={goalCycleNameError?.type === 'name' && goalCycleNameError?.helperText ? true : false}
										helperText={
											goalCycleNameError?.type === 'name' &&
											goalCycleNameError?.helperText && (
												<>
													<ErrorIcon />
													{goalCycleNameError?.helperText}
												</>
											)
										}
									/>
									<Typography variant='h6' className='input-label-pad'>
										{t('charactersRemaining')}: {getRemainingCharacter(goalCycleData?.name || '', 50)}
									</Typography>
								</Box>
								<Box className={`calendar-fields`}>
									<Box
										className={`add-timeframe-field helperText-bottom ${
											goalCycleTimeframeError?.type === 'timeframeDate' && goalCycleTimeframeError?.helperText
												? 'custom-error-field'
												: ''
										}`}
									>
										<FormLabel id='add-timeframe-start' component='legend' className='form-label-required'>
											<CalendarIcon />
											{t('timeframeLabel')}
											<i style={{ color: 'red' }}>*</i>
										</FormLabel>
										<Box className='calendar-start-end-fields'>
											<ReactDateRangePicker
												type={'ContributorList'}
												name='timeframeStartDate'
												selectedCycleDetails={{}}
												startDateValue={goalCycleData?.startDate ? new Date(goalCycleData?.startDate) : null}
												minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
												maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
												handleDateSelection={(range: any, start: any, end: any) => {
													if (range) {
														setIsGoalCycleFormEdited(true);
														setSelectedTimeframeStartDateRange(range);
														setGoalCycleData({ ...goalCycleData, startDate: range });
														setGoalCycleTimeframeError({
															error: '',
															helperText: '',
															type: '',
														});
													}
												}}
												isDateRangeTextFieldVisible={true}
												isOnlyDueDateVisible={false}
												allCycleDetails={[]}
												isCycleVisible={false}
												isEndOfMonthVisible={false}
												numberOfCalendars={Enums.ONE}
												selectionType={'single'}
												selectedDateRange={selectedTimeframeStartDateRange || null}
												setSelectedDateRange={(range: any) => {
													if (range) {
														setSelectedTimeframeStartDateRange(range);
													}
												}}
												calendarTitle={t('singleDateStartDateTitle')}
												placeholderText={t('selectStartDateLabel')}
												isDatePickerDisable={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
											/>
											{goalCycleTimeframeError?.type === 'timeframeDate' && goalCycleTimeframeError?.helperText ? (
												<Typography className='error-field'>
													<ErrorIcon /> {goalCycleTimeframeError?.helperText}
												</Typography>
											) : (
												<></>
											)}

											<ReactDateRangePicker
												type={'ContributorList'}
												name='timeframeEndDate'
												selectedCycleDetails={{}}
												startDateValue={goalCycleData?.endDate ? new Date(goalCycleData?.endDate) : null}
												minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
												maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
												handleDateSelection={(range: any, start: any, end: any) => {
													if (range) {
														setIsGoalCycleFormEdited(true);
														setSelectedTimeframeEndDateRange(range);
														setGoalCycleData({ ...goalCycleData, endDate: range });
														setGoalCycleTimeframeError({
															error: '',
															helperText: '',
															type: '',
														});
													}
												}}
												isDateRangeTextFieldVisible={true}
												isOnlyDueDateVisible={false}
												allCycleDetails={[]}
												isCycleVisible={false}
												isEndOfMonthVisible={false}
												numberOfCalendars={Enums.ONE}
												selectionType={'single'}
												selectedDateRange={selectedTimeframeEndDateRange || null}
												setSelectedDateRange={(range: any) => {
													if (range) {
														setSelectedTimeframeEndDateRange(range);
													}
												}}
												calendarTitle={t('singleDateTitle')}
												placeholderText={t('selectEndDateLabel')}
												isDatePickerDisable={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
											/>
										</Box>
									</Box>
									<Box
										className={`add-timeframe-field helperText-bottom ${
											goalCycleDurationError?.type === 'settingDurationDate' && goalCycleDurationError?.helperText
												? 'custom-error-field'
												: ''
										}`}
									>
										<FormLabel id='add-duration-start' component='legend' className='form-label-required'>
											<CalendarIcon />
											{t('settingDurationLabel')}
											<i style={{ color: 'red' }}>*</i>
										</FormLabel>
										<Box className='calendar-start-end-fields'>
											<ReactDateRangePicker
												type={'ContributorList'}
												name='settingDurationStartDate'
												selectedCycleDetails={{}}
												startDateValue={
													goalCycleData?.settingDurationStartDate
														? new Date(goalCycleData?.settingDurationStartDate)
														: null
												}
												minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
												maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
												handleDateSelection={(range: any, start: any, end: any) => {
													if (range) {
														setIsGoalCycleFormEdited(true);
														setSelectedDurationStartDateRange(range);
														setGoalCycleData({ ...goalCycleData, settingDurationStartDate: range });
														setGoalCycleDurationError({
															error: '',
															helperText: '',
															type: '',
														});
													}
												}}
												isDateRangeTextFieldVisible={true}
												isOnlyDueDateVisible={false}
												allCycleDetails={[]}
												isCycleVisible={false}
												isEndOfMonthVisible={false}
												numberOfCalendars={Enums.ONE}
												selectionType={'single'}
												selectedDateRange={selectedDurationStartDateRange || null}
												setSelectedDateRange={(range: any) => {
													if (range) {
														selectedDurationStartDateRange(range);
													}
												}}
												calendarTitle={t('singleDateStartDateTitle')}
												placeholderText={t('selectStartDateLabel')}
												isDatePickerDisable={
													goalCycleData?.status === EnumPerformanceGoalCycleStatus.Published ||
													goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed
												}
											/>
											{goalCycleDurationError?.type === 'settingDurationDate' && goalCycleDurationError?.helperText ? (
												<Typography className='error-field'>
													<ErrorIcon /> {goalCycleDurationError?.helperText}
												</Typography>
											) : (
												<></>
											)}

											<ReactDateRangePicker
												type={'ContributorList'}
												name='settingDurationEndDate'
												selectedCycleDetails={{}}
												startDateValue={
													goalCycleData?.settingDurationEndDate ? new Date(goalCycleData?.settingDurationEndDate) : null
												}
												minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
												maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
												handleDateSelection={(range: any, start: any, end: any) => {
													if (range) {
														setIsGoalCycleFormEdited(true);
														setSelectedDurationEndDateRange(range);
														setGoalCycleData({ ...goalCycleData, settingDurationEndDate: range });
														setGoalCycleDurationError({
															error: '',
															helperText: '',
															type: '',
														});
													}
												}}
												isDateRangeTextFieldVisible={true}
												isOnlyDueDateVisible={false}
												allCycleDetails={[]}
												isCycleVisible={false}
												isEndOfMonthVisible={false}
												numberOfCalendars={Enums.ONE}
												selectionType={'single'}
												selectedDateRange={selectedDurationEndDateRange || null}
												setSelectedDateRange={(range: any) => {
													if (range) {
														selectedDurationEndDateRange(range);
													}
												}}
												calendarTitle={t('singleDateTitle')}
												placeholderText={t('selectEndDateLabel')}
												isDatePickerDisable={false}
											/>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>

						{goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed && (
							<Box className='goal-cycle-notes'>
								<Typography variant='h6'>{t('noteCompleteGoalCycle')}</Typography>
							</Box>
						)}
					</Box>
					<AssignUsers
						{...props}
						setIsGoalCycleFormEdited={setIsGoalCycleFormEdited}
						selectedUsersOptions={selectedUsersOptions}
						setSelectedUsersOptions={setSelectedUsersOptions}
						setLoader={setLoader}
						loader={loader}
						goalCycleAssignedUserError={goalCycleAssignedUserError}
						setGoalCycleAssignedUserError={setGoalCycleAssignedUserError}
						goalCycleData={goalCycleData}
						setGoalCycleData={setGoalCycleData}
					/>
					<Box className='create-goal-cycle-actions'>
						{goalCycleData?.performanceGoalCycleId === 0 || goalCycleData?.status === Enums.ONE ? (
							<OKRButton
								className='btn-link'
								id='save-as-draft'
								handleClick={saveGoalCycleAsDraft}
								text={t('saveAsDraft')}
								disabled={loader}
							/>
						) : (
							<></>
						)}
						<OKRButton
							className='btn-primary'
							id='save-btn'
							disabled={loader}
							handleClick={saveGoalCycleAsSubmit}
							text={t('saveBtn')}
						/>
					</Box>
				</div>
			</Box>

			{goalCycleModalProps && goalCycleModalProps.open && (
				<AlertDialog
					module={goalCycleModalProps.showDownload ? 'goalCycleCreateDownloadUser' : 'goalCycleCreate'}
					isCancel={goalCycleModalProps?.type === 'OverlapCycleUsers' ? true : false}
					message={goalCycleModalProps?.message || ''}
					handleCloseModal={handleCloseGoalCycleModal}
					modalOpen={goalCycleModalProps.open}
					showDownload={goalCycleModalProps.showDownload}
					handleDownloadUserList={handleDownloadUserList}
				/>
			)}
		</>
	);
};
