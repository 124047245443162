import {
	NOTIFICATION_BASE_URL,
	UserManagement,
	OkrManagement,
	OkrDBLogin,
	FEEDBACK_BASE_URL,
	AlignmentMap,
	Tenant,
	GuideTour,
	Onboarding,
	AdminRoles,
	OkrUserManagement,
	OkrAuth,
	OkrTeamManagement,
	TeamManagement,
	OkrReports,
	OkrTask,
	okrNote,
	okrConversation,
	okrCheckins,
	OkrTrialEnv,
	versionDetails,
	UnlockUServer,
	TemplateController,
	ubotApiEndpointSuffix,
	aiBotBaseUrl,
	aiBotOKRBaseUrl,
} from './url';

export const NOTIFICATION = `${NOTIFICATION_BASE_URL}/Notifications`;
export const UNREAD_NOTIFICATION = `${NOTIFICATION_BASE_URL}/IsUnreadNotification`;
// export const NOTIFICATION_LISTING = `${NOTIFICATION_BASE_URL}/NotificationsAsync`;
// export const READ_ALERTS = `${NOTIFICATION_BASE_URL}/ReadAlerts`;
export const NOTIFICATION_ALL_LISTING = `${NOTIFICATION_BASE_URL}/AllNotifications`;

/** ------UserManagement------- */
export const AZURE_SIGN_IN = `${OkrUserManagement}/Identity/userByToken`;
export const ORG_CURRENT_CYCLE = `${TeamManagement}/cycles/currentcycle`;
export const SIGN_IN = `${UserManagement}/User/Login`;
export const SIGN_IN_DB = `${OkrUserManagement}/Identity/UserValidation`;
export const CREATE_PASSWORD = `${OkrUserManagement}/Identity/CreatePassword`;
export const FORGET_PASSWORD = `${OkrUserManagement}/Identity/ForgetPassword`;
export const LOGOUT = `${UserManagement}/User/LogOut`;
export const IS_FEDERATION_USER = `${UserManagement}/User/IsFederationUser`;
export const SSO_LOGIN = `${UserManagement}/PassportSso/SsoAuthentication`;
export const FORGOT_PASSWORD = `${UserManagement}/User/SendResetPasswordMail`;
export const RESET_PASSWORD = `${UserManagement}/User/ResetPassword`;
export const RESET_PASSWORD_MAIL = `${UserManagement}/User/ReSendResetPasswordMail`;
export const OKR_STATUS = `${UserManagement}/Master/GetAllOkrFilters`;
export const LIST_USER = `${OkrUserManagement}/users/getAll`;
export const ADD_USER = `${OkrUserManagement}/users`;
export const ADD_AD_USER = `${UserManagement}/User/AddAdUser`;
export const EDIT_USER = `${OkrUserManagement}/users`;
export const UPLOAD_BULK_USER = `${OkrUserManagement}/users/Bulk`;
export const UPLOAD_BULK_EMAILS = `${OkrUserManagement}/users/bulkEditEmails`;
export const SEARCH_USER = `${UserManagement}/User/Search`;
export const GET_USER_BY_ID = `${OkrUserManagement}/users`;
export const DELETE_USER = `${OkrUserManagement}/users`;
export const CHANGE_ROLE = `${OkrAuth}/employeeRole/assignRole`;
export const CHANGE_REPORTING = `${OkrUserManagement}/users/Reporting`;
export const GET_USER_DETAILS = `${OkrUserManagement}/users`;
export const IMPERSONATE_USER = `${OkrUserManagement}/Identity/ImpersonateUserByToken`;
export const USER_DEPARTMENT_LIST = `${OkrUserManagement}/users/Department`;
export const GET_ALL_USERS = `${UserManagement}/User/GetAllusers`;
export const LIST_ROLES = `${UserManagement}/Permission/GetAllRolePermission`;
export const ADD_ROLES = `${UserManagement}/Role/AddRole`;
export const EDIT_ROLES = `${UserManagement}/Role/EditRole`;
export const ASSIGN_ROLES = `${UserManagement}/Role/AssignUsers`;
export const UPDATE_ROLES_STATUS = `${UserManagement}/Role/ActiveInactiveRole`;
export const UPDATE_ROLES_PERMISSION = `${UserManagement}/Permission/EditRolePermission`;
export const UPDATE_ROLENAME = `${AdminRoles}/roles/UpdateRoleName`;
export const UNLOCK_USER = `${OkrManagement}/MyGoals/BulkUnlockApprove`;
export const CHANGE_ORGANIZATION = `${UserManagement}/User/ChangeUserOrganisation`;
export const DOWNLOAD_USER_SAMPLE = `${UserManagement}/User/DownloadCSV`;
export const GET_DESIGNATION = `${UserManagement}/User/Designation`;
export const GET_PROFILE = `${UserManagement}/User/EmployeeProfile`;
export const UPDATE_PROFILE = `${UserManagement}/User/UpdateContactDetail`;
export const UPDATE_PROFILE_PIC = `${UserManagement}/User/UpdateProfileImage`;
export const DELETE_PROFILE_PIC = `${UserManagement}/User/DeleteImage`;
export const UPDATE_PROFILE_PASSWORD = `${UserManagement}/User/ChangePassword`;
export const VALIDATE_BULK_USER = `${OkrUserManagement}/users/bulkValidate`;
export const VALIDATE_BULK_EMAILS = `${OkrUserManagement}/users/bulkEditEmailsValidate`;
export const VALIDATE_BULK_GOAL_ROLE = `${OkrUserManagement}/users/bulkGoalFormMappingValidate`;
export const UPLOAD_BULK_GOAL_ROLE = `${OkrUserManagement}/users/bulkGoalFormMapping`;

export const INVITE_USER = `${OkrUserManagement}/users/inviteUser`;
export const GET_GOALS_FORM = `${UserManagement}/PerformanceGoalForm/List`;
export const GET_GOALS_CYCLE_LIST = `${UserManagement}/PerformanceGoalCycle/List`;
export const GET_GOALS_REVIEW_WORKFLOW_LIST = `${UserManagement}/PerformanceGoalReviewWorkFlow/List`;
export const GET_REVIEW_USER_LIST = `${UserManagement}/PerformanceGoalCycleEmployee/List`;
export const POST_GOALS_REVIEW = `${UserManagement}/PerformanceGoalReviewCycle`;
export const GET_GOALS_REVIEW_LIST = `${UserManagement}/PerformanceGoalReviewCycle/ReviewCycle`;
export const GET_SELF_REVIEW_LISTING = `${UserManagement}/PerformanceGoalRatingAssessment/ReviewListing`;
export const GET_GOALS_CYCLE_DETAILS = `${UserManagement}/PerformanceGoalCycle/GetDetails`;
export const PERFORMANCE_FORM_VALIDATE_BULK_UPLOAD = `${UserManagement}/PerformanceGoalForm/ValidateBulkUploadGoalForm`;
export const PERFORMANCE_FORM_BULK_UPLOAD = `${UserManagement}/PerformanceGoalForm/BulkUploadGoalForm`;
export const PERFORMANCE_GOALS_CYCLE = `${UserManagement}/PerformanceGoalCycle`;
export const IS_TIMEFRAME_OVERLAP = `${UserManagement}/PerformanceGoalCycle/TimeframeOverlap`;
export const ASSIGN_USER_SEARCH_BY_NAME = `${UserManagement}/PerformanceGoalCycleEmployee/SearchAssignUserByName`;
export const VALIDATE_MANUAL_ASSIGNED_USER = `${UserManagement}/PerformanceGoalCycleEmployee/ValidateManualAssignedUser`;
export const VALIDATE_BULK_ASSIGNED_USER = `${UserManagement}/PerformanceGoalCycleEmployee/ValidateBulkAssignedUser`;
export const GET_WORKFLOW_REVERSAL_LIST = `${UserManagement}/PerformanceGoalWorkflow/List`;
export const GET_GOAL_REVIEW_LIST = `${UserManagement}/PerformanceGoalReviewCycle/List`;
export const GOALS_REVIEW_CYCLE_DELETE = `${UserManagement}/PerformanceGoalReviewCycle`;

export const LICENSE_DETAILS = `${OkrUserManagement}/users/licence`;
export const LIST_ORG = `${TeamManagement}/organization`;
export const ADD_ORG = `${UserManagement}/Organisation/AddOrganisation`;
export const ADD_PARENT = `${UserManagement}/Organisation/AddParentToParent`;
export const GET_ORGANIZATION = `${UserManagement}/Organisation/GetOrganisationById`;
export const UPDATE_ORGANIZATION = `${UserManagement}/Organisation/EditOrganisation`;
export const ADD_CHILD = `${UserManagement}/Organisation/AddChildOrganisation`;
export const EDIT_CHILD = `${UserManagement}/Organisation/EditChildOrganisation`;
export const GET_MANAGE_BADGES = `${UserManagement}/Recognition/getallcategory`;
export const ADD_BADGES = `${UserManagement}/Recognition/createcategory`;
export const BADGES_STATUS = `${UserManagement}/Recognition/isrecognitioncategory`;
export const DELETE_BADGES = `${UserManagement}/Recognition/recognitioncategory`;

export const DELETE_ORGANIZATION = `${UserManagement}/Organisation/DeleteOrganisation`;
export const UPLOAD_LOGO = `${TeamManagement}/teams/UploadLogo`;
export const OKR_MASTER_DATA = `${UserManagement}/Master/OkrMasterData`;

/** ------OkrManagement------- */
export const OKR_LISTING = `${OkrManagement}/MyGoals/MyGoal`;
export const EMPLOYEE_VIEW = `${OkrManagement}/People/EmployeeView`;
export const PEOPLE_ALIGNMENT_VIEW = `${OkrManagement}/People/PeopleAlignmentView`;
export const ADD_GOALS = `${OkrManagement}/MyGoals/Goals`;
export const ALIGN_GOALS = `${OkrManagement}/MyGoals/Align`;
export const CONTRIBUTORS = `${OkrManagement}/MyGoals/Contributors`;
export const UPDATE_KR_PROGRESS = `${OkrManagement}/MyGoals/keyScore`;
export const UNLOCK_REQUEST = `${OkrManagement}/MyGoals/AskUnLock`;
export const ALIGN_STATUS = `${OkrManagement}/MyGoals/AlignStatus`;
export const DOWNLOAD_GOAL_PDF = `${OkrManagement}/MyGoals/DownloadPdf`;
export const QUARTER_REPORT = `${FEEDBACK_BASE_URL}/Export/OrgReport`;
export const STATUS_REPORT = `${OkrManagement}/Report/StatusReport`;
export const STATUS_REPORT_ENGAGEMENT = `${FEEDBACK_BASE_URL}/Export/EngagementReport`;
export const PROGRESS_REPORT = `${OkrManagement}/Report/ProgressReport`;
export const UPDATE_OKR_SEQUENCE = `${OkrManagement}/MyGoals/UpdateSequence`;
export const KR_CONTRIBUTORS = `${OkrManagement}/MyGoals/KrContributors`;
export const UPDATE_GOAL_KR_NAME = `${OkrManagement}/MyGoals/GoalDescription`;
export const UPDATE_GOAL_ATTRIBUTES = `${OkrManagement}/MyGoals/GoalObjective`;
export const UPDATE_KR_ATTRIBUTES = `${OkrManagement}/MyGoals/GoalKey`;
export const ACCEPT_KR = `${OkrManagement}/MyGoals/KrContributor`;
export const UPDATE_KR_PROGRESS_VALUE = `${OkrManagement}/MyGoals/UpdateKrValue`;
export const BECOME_CONTRIBUTOR = `${OkrManagement}/MyGoals/BecomeContributor`;
export const GET_KR_DETAIL = `${OkrManagement}/MyGoals/GoalKeyDetail`;
export const GET_OKR_DETAIL = `${OkrManagement}/MyGoals/GoalObjectiveDetail`;
export const RESET_OBJECTIVE = `${OkrManagement}/MyGoals/ResetOkr`;
export const GET_LINK_OWNER_DATA = `${UserManagement}/Organisation/TeamDetailsById`;

/** ---------- dashboard --------------- */
export const DASHBOARD_LIST = `${OkrManagement}/Dashboard/AllOkrDashboard`;
export const MY_GOAL_DETAIL = `${OkrManagement}/Dashboard/GetGoalDetail`;
export const MY_TEAM_OKR_VIEW = `${OkrManagement}/Dashboard/TeamOkrView`;
export const NUDGE_TEAM = `${OkrManagement}/Dashboard/NudgeTeam`;
export const NUDGE_PERSON = `${OkrManagement}/Dashboard/NudgeDirectReporting`;
export const MY_TEAM_OKR_DETAILS_VIEW = `${OkrManagement}/Dashboard/TeamOkrGoalDetailsById`;
export const DELTA_SCORE = `${OkrManagement}/Dashboard/DeltaScore`;
export const RECENT_CONTRIBUTION_LIST = `${OkrManagement}/Dashboard/RecentContribution`;
// Dashboard Progress summary
export const DASHBOARD_PROGRESS = `${OkrManagement}/Dashboard/AllOkrProgressCard`;
export const TEAMSOKR_PROGRESS = `${OkrManagement}/Dashboard/TeamOkrProgressCard`;
export const TEAM_VIEW_LIST = `${OkrManagement}/Dashboard/TeamView`;
export const DR_VIEW = `${OkrManagement}/Dashboard/TeamAndDrEmployee`;
export const DR_TEAM_VIEW = `${OkrManagement}/Dashboard/EmployeeTeam`;

/** Import api */
export const OKR_IMPORT = `${OkrManagement}/Import`;
export const OKR_CATEGORY_IMPORT = `${OkrManagement}/Import/ImportCategory`;
export const KR_LISTING = `${OkrManagement}/MyGoals/LinkCheckInKeyResults`;
export const MY_OKR_IMPORT = `${OkrManagement}/Import/ImportOkr`;

/** ---------- alignment --------------- */
export const ALIGNMENT_LIST = `${AlignmentMap}/Alignment/OkrView`;
export const ALIGNMENT_DETAIL_LIST = `${OkrManagement}/Alignment/AssociateObjectiveAlignment`;
export const ALIGNMENT_PARENT_DETAIL = `${OkrManagement}/Alignment/GetAllParentAlignment`;
export const ALIGNMENT_GRAPH_DETAIL = `${OkrManagement}/Alignment/AlignmentGoalMapGraph`;
export const PEOPLE_MAP_LIST = `${AlignmentMap}/People/PeopleView`;
export const ALIGNMENT_TEAM_LIST = `${AlignmentMap}/Alignment/TeamsOkr`;
export const ALIGNMENT_DIRECT_REPORTS_LIST = `${OkrManagement}/Dashboard/DirectReports`;
export const UPDATE_PROGRESS = `${OkrManagement}/MyGoals/UpdateKrValueAlignmentMap`;
export const UPDATE_DUE_DATE = `${OkrManagement}/MyGoals/UpdateDueDate`;

/** ------Reports------- */
export const AVG_OKR_REPORT = `${OkrManagement}/Report/AvgOkrReport`;
export const WEEKLY_KR_REPORT = `${OkrManagement}/Report/WeeklyKrReports`;
export const MOST_FEEDBACK_REPORT = `${FEEDBACK_BASE_URL}/Feedback/MostFeedbackReport`;
export const PERSONAL_FEEDBACK_REPORT = `${FEEDBACK_BASE_URL}/export`;
export const WEEKLY_KR_GROWTH_REPORT = `${OkrManagement}/Report/KrUpdateGrowthCycle`;
export const LEAST_RISK_OKR_REPORT = `${OkrManagement}/Report/MostLeastOkrRisk`;
export const LEAST_RISK_KR_REPORT = `${OkrManagement}/Report/MostLeastOkrKeyResultRisk`;
export const GET_TEAMS_PERFORMANCE = `${OkrManagement}/Report/TeamPerformance`;
export const GET_TEAMS_PROGRESS = `${OkrManagement}/Report/TeamPerformance`;
export const GET_LEADING_TEAMS = `${TeamManagement}/teams/LeadingTeam`;
export const GET_MY_LEADING_TEAMS = `${OkrReports}/MyOkrProgressReport/LeadingTeam`;

/** Global Search */
export const GLOBAL_SEARCH = `${UserManagement}/User/Search`;
export const GLOBAL_SEARCH_WITH_TEAM = `${UserManagement}/User/GlobalSearch`;
export const SEARCH_IN_TEAM = `${UserManagement}/User/SearchTeamEmployee`;
export const SEARCH_IN_ROLE = `${OkrUserManagement}/users/search`;

/** Feedback */
export const GIVE_FEEDBACK = `${FEEDBACK_BASE_URL}/Feedback/Provide`;
export const ASK_FEEDBACK = `${FEEDBACK_BASE_URL}/Feedback/Ask`;
export const FETCH_FEEDBACK = `${FEEDBACK_BASE_URL}/Feedback/Feedback`;
export const FEEDBACK_1To1 = `${FEEDBACK_BASE_URL}/Feedback/OneToOne/Request`;
export const COMMENT_FEEDBACK = `${FEEDBACK_BASE_URL}/Feedback/Comment`;
export const REDIRECT_FEEDBACK = `${FEEDBACK_BASE_URL}/Feedback/MyGoalFeedbackResponse`;

/** common */

export const CYCLE_DETAILS = `${TeamManagement}/cycles/TeamCycleDetails`;
export const IMPORT_CYCLE_DETAILS = `${UserManagement}/Organisation/GetImportOkrCycle`;
export const GET_OWNER_TEAMS = `${OkrManagement}/Dashboard/TeamDetailsById`;
export const CHECKIN_MASTER_DATA = `${UserManagement}/Master/CheckInVisible`;

/** Personal Feedback */
export const PERSONAL_FEEDBACK_OneOnOne = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/PersonalFeedbackOneOnOne`;
export const PERSONAL_FEEDBACK_ASK = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/AskPersonalFeedback`;
export const PERSONAL_FEEDBACK_ASK_DETAIL = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/AskedPersonalFeedbackDetails`;
export const PERSONAL_FEEDBACK_FETCH_GIVING_FEEDBACK = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/ProvidePersonalFeedback`;
export const PENDING_PERSONAL_FEEDBACK = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/PendingPersonalFeedback`;
export const ALL_FEEDBACK_COUNT = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/PersonalFeedbackCount`;
export const PERSONAL_FEEDBACK_UPDATE_GIVING_FEEDBACK = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/Provide`;
export const PERSONAL_FEEDBACK_CRITERIA_MASTER = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/CriteriaMaster`;
export const PERSONAL_FEEDBACK_REQUEST_AGAIN = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/RequestFeedbackAgain`;
export const PERSONAL_FEEDBACK_CANCEL_REQUEST = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/CancelFeedback`;
export const PERSONAL_FEEDBACK_VIEW = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/PersonalFeedback`;
export const PERSONAL_FEEDBACK_DETAIL = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/PersonalFeedbackResponse`;
export const PERSONAL_FEEDBACK_ACCEPT_REJECT = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/ApproveRejectRequest`;
export const GET_LINK_OBJECTIVE_DATA = `${OkrManagement}/MyGoals/LinkObjectiveDetail`;
export const OWNER_CHANGE_DATA = `${OkrManagement}/MyGoals/ChangeOwner`;
export const VIRTUAL_ALIGNMENT = `${OkrManagement}/Dashboard/VirtualAlignment`;
export const PEOPLEVIEW_PERSONAL_FEEDBACK = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/PeopleViewPersonalFeedback`;

/** Tenant */
export const TENANT_DATA = `${Tenant}/Tenant`;
export const TENANT_DOWNTIME = `${Tenant}/DownTime`;
export const TENANT_DATA_OKR = `${OkrTrialEnv}/tenantDetails`;
export const USER_VERSION = `${Tenant}/UserVersion`;
export const USER_FORCE_LOGOUT = `${Tenant}/ForcelogOut`;

/**Guided Tour */
export const GUIDED_TOUR = `${GuideTour}/GuidedTour`;
export const GUIDED_TOUR_ANY_ACTIVITY = `${GuideTour}/GuidedTourAnyActivity`;

export const FETCH_ONBOARDING = `${Onboarding}/OnBoarding`;
export const UPDATE_ONBOARDING = `${Onboarding}/OnBoardingActionsCount`;
export const ONBOARDING_COUNT = `${Onboarding}/OnBoardingControlDetailById`;

// Archive

export const ARCHIVE_LIST = `${OkrManagement}/Dashboard/Archive`;
export const OBJECTIVE_DETAILS = `${OkrManagement}/MyGoals/GetObjectiveDetails`;

//Add role and permission
export const ADD_ROLES_PERMISSION = `${AdminRoles}/roles`;
export const ADMIN_ROLES_AND_PERMISSION = `${AdminRoles}/employeeRolePermissions`;
export const GET_PERMISSIONS = `${AdminRoles}/permissions`;
export const GET_ROLES = `${AdminRoles}/roles/rolepermissions`;
export const EMPLOYEE_PERMISSION = `${AdminRoles}/employeeRole/permissions`;
export const UPDATE_ROLE = `${AdminRoles}/roles`;
export const GET_ROLE = `${AdminRoles}/roles/roleDetails`;
export const UPDATE_PERMISSION = `${AdminRoles}/permissions/assignRolePermission`;
export const DELETE_ROLE = `${AdminRoles}/roles`;
export const CLONE_ROLE = `${AdminRoles}/roles/clone`;
export const ASSIGN_USER_TO_ROLE = `${AdminRoles}/employeeRole/assignRole`;
export const GET_EMPLOYEE_PERMISSION = `${AdminRoles}/employeePermissions`;
export const SAVE_EMPLOYEE_PERMISSIONS = `${AdminRoles}/employeePermissions`;
export const GET_ROLE_PERMISSION = `${AdminRoles}/roles/permissions`;

//Add team
export const GET_PARENT_TEAM = `${OkrTeamManagement}/teams`;
export const ADD_TEAM = `${OkrTeamManagement}/teams`;
export const ASSIGNED_EMPLOYEE_TEAM = `${OkrTeamManagement}/Employeeteam`;
export const ORG_CHART_VIEW = `${OkrTeamManagement}/organization/OrgChartView`;

// Cycle data
export const GET_CYCLE_DATA = `${TeamManagement}/cycles`;
export const GET_PARENT_ORGANSATION = `${TeamManagement}/organization/parent`;
export const UPDATE_PARENT_ORGANSATION = `${TeamManagement}/organization`;
export const DELETE_TEAM = `${TeamManagement}/teams`;
export const GET_USER_TEAMS = `${TeamManagement}/teams/teamdetails`;
export const UPDATE_ALIGN_SETTINGS = `${TeamManagement}/organization/AlignSettings`;
export const UPDATE_ENGAGE_SETTINGS = `${TeamManagement}/organization/EngageSettings`;

export const ORG_REPORTS = `${OkrReports}/OrganizationReport`;
export const MY_PROGRESS_REPORTS = `${OkrReports}/MyOkrProgressReport`;
export const OVERALL_PERFORMANCE = `${OkrReports}/EngagementReport/MyEngagementReport`;
export const MY_TEAM_PROGRESS_REPORTS = `${OkrReports}/TeamOkrProgressReport`;
export const OVERALL_TEAM_PERFORMANCE = `${OkrReports}/EngagementReport/TeamEngagementReport`;
export const LEADING_TEAM = `${OkrReports}/MyOkrProgressReport/LeadingTeam`;
export const GET_OKR_DETAILS_DASHOBOARD = `${OkrManagement}/Dashboard/AllOkrProgressLanding`;

//CFR
export const OBJECTIVE_TASK = `${OkrTask}/task`;
export const DELETE_TASK = `${OkrTask}/task`;
export const UPDATE_TASK = `${OkrTask}/task`;
export const TASK_COMPLETED = `${OkrTask}/task/taskCompleted`;

export const GET_NOTES = `${okrNote}/notes`;
export const UPLOAD_NOTES_FILES = `${okrNote}/notes/UploadNotes`;
export const POST_PROGRESS_CONVERSATION = `${okrConversation}/conversation`;
export const GET_NOTEBY_ID = `${okrNote}/notes/IsEmployeeTag`;
export const GET_CONVERSATION_BY_ID = `${okrConversation}/conversation/IsEmployeeTag`;
export const GET_CONVERSATION_REPLY = `${okrConversation}/conversation/ConversationReply`;

//Conversation
export const GET_CONVERSATION = `${okrConversation}/conversation`;
export const UPLOAD_CONVERSATION_FILES = `${okrConversation}/conversation/UploadConversation`;
export const MULTI_UPLOAD_CONVERSATION_FILES = `${UserManagement}/User/upload`;
export const LIKE_CONVERSATION = `${okrConversation}/conversation/like`;
export const WALL_OF_FAME_DASHBOARD = `${okrConversation}/recognition/mywalloffamedashboard`;
export const GET_CONVERSATION_TASK_REPLY = `${okrConversation}/conversation/ConversationTaskReply`;

//Checkins
export const CHECKINSTATUS = `${okrCheckins}/checkIn/IsCheckInSubmitted`;
export const CHECKINDATES = `${okrCheckins}/checkIn/CheckInWeeklyDates`;
export const CHECKINDATA = `${okrCheckins}/checkIn`;
export const CHECKIN_VISIBILTY_UPDATE_DATA = `${okrCheckins}/checkIn`;
export const DIRECT_REPORTEES = `${okrCheckins}/checkIn/directreports`;
export const GET_TASK_LIST = `${okrCheckins}/taskList`;
export const POST_TASK_IMPORT = `${okrCheckins}/checkIn/Import`;
export const DASHBOARD_CHECKIN_DETAILS = `${okrCheckins}/checkIn/DashboardCheckIn`;
export const CHECKINSETTINGLIST = `${okrCheckins}/checkIn/employeecheckinvisible`;
export const SAVECHECKINDATA = `${okrCheckins}/checkIn/addemployeecheckin`;
export const DELETE_CHECKINDATA = `${okrCheckins}/checkIn/employeecheckinvisible`;
export const CHECKINALREADY_ADDED = `${okrCheckins}/checkIn/isaddedemployeecheckin`;

//confidence
export const UPDATE_CONFIDENCE = `${OkrManagement}/Confidence`;
export const GET_WEEKLY_TREND_DETAILS = `${OkrManagement}/Report/AuditWeeklyTrend`;
export const GET_CONFIDENCE_ALERT = `${OkrManagement}/Confidence/Alert`;

//Free Trial
export const FREE_TRIAL = `${OkrTrialEnv}/bookdemo`;
export const FREE_TRIAL_EMAILID = `${OkrTrialEnv}/check-emailid`;
export const FREE_TRIAL_DOMAIN = `${OkrTrialEnv}/check-domain`;
export const FREE_TRIAL_PASSWORD = `${OkrTrialEnv}/check-password`;
export const OKR_TENANTS_DETAILS = `${OkrTrialEnv}/tenantDetails`;

// About Us
export const GET_VERSION_DETAILS = `${versionDetails}/Copyright`;

//Praise
export const POST_RECOGNITION = `${OkrManagement}/People/Recognition`;

//Manage Org
export const MANAGE_LIST_ORG = `${TeamManagement}/organization/GetAllOrganizations`;

//Bulk Upload Team
export const ADD_BULK_TEAM = `${TeamManagement}/bulk/teamupload`;

//Under Assign
export const UNDER_ASSIGNED = `${OkrManagement}/MyGoals/UnderAndOverAssign`;

/**
 * Admin Setting related API Urls
 */
export const ADD_PERFORMANCE_CYCLE = `${UserManagement}/PerformanceCycle`;
export const PUBLISH_PERFORMANCE_CYCLE = `${UserManagement}/PerformanceCycle/Publish`;
export const PERFORMANCE_CYCLE_LIST = `${UserManagement}/PerformanceCycle/List`;
export const PERFORMANCE_CYCLE_OVERLAP = `${UserManagement}/PerformanceCycle/Overlap`;
export const PERFORMANCE_CYCLE_LINKED_FORM = `${UserManagement}/PerformanceCycle/GetLinkedForm`;
export const PERFORMANCE_CYCLE_CYCLE = `${UserManagement}/PerformanceCycle/Cycle`;
export const PERFORMANCE_CYCLE_ACTIVE_CYCLE = `${UserManagement}/PerformanceCycle/ActiveCycle`;
export const IS_NEW_PERFORMANCE_ROLE = `${UserManagement}/PerformanceAlert/IsNewPerformanceRole`;
export const ADMIN_PREVIEW_FORM = `${UserManagement}/PerformanceCycle/AssessmentPreview`;
// Performance Role API url list
export const PERFORMANCE_ROLE_DETAIL = `${UserManagement}/PerformanceRole`;
export const ANY_DEFAULT_OKR = `${OkrUserManagement}/users/AnyDefaultOkrs`;
export const PERFORMANCE_ROLE_ASSIGNED = `${UserManagement}/PerformanceRole/Assigned`;
export const PERFORMANCE_CYCLE_EMPLOYEE = `${UserManagement}/PerformanceCycleEmployee/List`;
export const PERFORMANCE_CYCLE_EMPLOYEE_INCLUDE = `${UserManagement}/PerformanceCycleEmployee/Include`;
// Rating Form API Url list
export const RATING_FORM_LIST = `${UserManagement}/RatingForm/List`;
export const RATING_FORM = `${UserManagement}/RatingForm`;
export const RATING_FORM_DETAILS = `${UserManagement}/RatingForm/RatingFormDetails`;
// Manage Scale
export const QUESTION_TYPE_MASTER = `${UserManagement}/Master/QuestionType`;
export const MANAGE_SCALE_LIST = `${UserManagement}/PerformanceScale/ManageScale`;
export const ADD_UPDATE_SCALE = `${UserManagement}/PerformanceScale`;
export const PERFORMANCE_TAB_COUNT = `${UserManagement}/RatingForm/PerformanceTabCount`;
export const UPDATE_MANAGE_SCALE_STATUS = `${UserManagement}/PerformanceScale/UpdateStatus`;
export const DELETE_MANAGE_SCALE = `${UserManagement}/PerformanceScale/DeleteScale`;
export const PERFORMANCE_GOAL_TAB_COUNT = `${UserManagement}/PerformanceGoalForm/PerformanceGoalTabCount`;

/**
 * Perform
 */

export const GET_ASSESSMENT_TEAMLIST = `${UserManagement}/Assessment/MyTeamsAssessmentDetails`;
export const MULTIPLE_EMPLOYEE_ASSESSMENT = `${UserManagement}/Assessment/GetMultipleAssessment`;
export const SAVE_RATING_FEEDBACK = `${UserManagement}/Assessment/Multiple`;

// My Assessment API Url list
export const GET_PERFORMANCE_CYCLE = `${UserManagement}/PerformanceCycle/Cycle`;
export const GET_PERFORMANCE_ALERT = `${UserManagement}/PerformanceAlert`;
export const GET_FINAL_RATING = `${UserManagement}/FinalRating`;
export const GET_EMPLOYEES_FINAL_RATING = `${UserManagement}/FinalRating/GetEmployeeFinalRating`;
export const SAVE_EMPLOYEES_FINAL_RATING = `${UserManagement}/FinalRating/FinalRating`;
export const SAVE_MULTIPLE_EMPLOYEES_MANAGER_ASSESSMENT = `${UserManagement}/Assessment/Multiple`;
export const GET_SNAPSHOT_DATA = `${UserManagement}/PerformanceCycleEmployee/AllPerformanceCycleOkr`;
export const SAVE_SELF_ASSESSMENT = `${UserManagement}/Assessment`;
export const IS_USER_EXLUDED = `${UserManagement}/PerformanceCycleEmployee/IsExcluded`;

export const GET_THREE_SIXTY_ASSESSMENT_LIST = `${UserManagement}/Assessment/ThreeSixtyNominee`;
export const VALIDATE_IS_NOMINEE_DELETED = `${UserManagement}/Nominee/IsNomineeDeleted`;
export const GET_ASSESSMENT_VISIBILITY = `${UserManagement}/Assessment/AssessmentVisibility`;

/**
 * Request 1-On-1 related API urls
 */
export const GET_ALL_MOM_REQUEST_LIST = `${FEEDBACK_BASE_URL}/OneToOne/GetAllMoms`;
export const GET_ONE_ON_ONE_REQUEST_LIST = `${FEEDBACK_BASE_URL}/OneToOne/OneToOne`;
export const ONE_ON_ONE_REQUEST = `${FEEDBACK_BASE_URL}/OneToOne`;
export const ONE_ON_ONE_LAUNCH_REQUEST = `${FEEDBACK_BASE_URL}/OneToOne/Launch`;
export const ONE_ON_ONE_LAUNCH_DATE_REQUEST = `${FEEDBACK_BASE_URL}/OneToOne/LaunchDate`;
export const ONE_ON_ONE_TEAM_DETAILS = `${FEEDBACK_BASE_URL}/OneToOne/OneToOneTeamDetails`;
export const GET_USER_NOTES = `${okrNote}/notes/UserNotes`;
export const GET_USER_TASKS = `${OkrTask}/task/UserTask`;
export const ONE_ON_ONE_TASKS = `${OkrTask}/task`;
export const ONE_ON_ONE_MEETING_NOTES = `${FEEDBACK_BASE_URL}/Export/OneToOneReport`;

export const GET_RECOMMENDED_COURSES = `${UnlockUServer}`;
export const DASHBOARD_WIDGET_DATA = `${UserManagement}/PerformanceCycle/DashboardWidget`;
export const DASHBOARD_ASSESSMENT_DATA = `${UserManagement}/PerformanceCycle/PerformanceDashboardWidget`;
export const DASHBOARD_FEEDBACK_REQUEST_ONE_ON_ONE = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/LandingFeedbackRequest1On1Count`;
export const PERFORMANCE_GOAL_DASHBOARD_WIDGET = `${UserManagement}/PerformanceGoalCycle/PerformanceGoalDashboardWidget`;

//Recognize
export const GET_ALL_RECOGNIZATION = `${okrConversation}/recognition/OrgRecognition`;
export const SAVE_RECOGNIZATION = `${okrConversation}/recognition`;
export const GET_ALL_BADGES = `${okrConversation}/recognition/badges`;
export const DELETE_RECOGNIZE_POST = `${okrConversation}/recognition`;
export const SAVE_COMMENTS = `${okrConversation}/recognition/Comments`;
export const GET_ALL_COMMENTS = `${okrConversation}/recognition/Comments`;
export const DELETE_COMMENT_POST = `${okrConversation}/recognition/Comments`;
export const UPDATE_RECOGNIZE_POST = `${okrConversation}/recognition`;
export const GET_ALL_FAME_BADGES = `${okrConversation}/recognition/mywalloffame`;
export const GET_LIKE_RECOGNIZE = `${okrConversation}/recognition/RecognitionLike`;
export const GET_TEAMS_BY_EMP = `${okrConversation}/recognition/teamsleaderboard`;
export const GET_TEAMS_BY_RECOG = `${okrConversation}/recognition/employeesleaderboard`;
export const RECOGNITION_SEARCH_WITH_TEAM = `${UserManagement}/User/RecognitionSearch`;
/**
 * API URL Performance Assessment Reports
 */
export const ASSESSMENT_QUICK_STATUS_REPORT = `${FEEDBACK_BASE_URL}/Export/performance-quick-report`;
export const ASSESSMENT_DETAILED_STATUS_REPORT = `${FEEDBACK_BASE_URL}/Export/performance-detail-report`;

// API URL Global Task
export const GET_GLOBAL_TASK = `${OkrTask}/task/GlobalTask`;
export const GET_GLOBAL_TASK_SOURCE_LIST = `${OkrTask}/task/TaskSourceList`;

export const USER_LIST_REPORT = `${FEEDBACK_BASE_URL}/Export/users-report`;

//Nominee
export const GET_NOMINEE_LIST = `${UserManagement}/Nominee`;
export const DELETE_NOMINEE = `${UserManagement}/Nominee`;
export const ADD_NOMINEE = `${UserManagement}/Nominee`;
export const NOMINEE_CHECK = `${UserManagement}/Nominee/IsNomineeAdded`;
export const MANAGER_ACCESSOR_VIEW = `${UserManagement}/Nominee/ManagerAccessorView`;

export const ONE_ON_ONE_REPORT = `${FEEDBACK_BASE_URL}/Export/oneonone-report`;
export const RECOGNITION_REPORT = `${FEEDBACK_BASE_URL}/Export/recognition-report`;
export const CHECK_IN_REPORT = `${FEEDBACK_BASE_URL}/Export/check-in-report`;
export const EXPORT_MY_OKR_REPORT = `${FEEDBACK_BASE_URL}/Export/MyOKRReport`;

/**
 * Strategic Objective API details
 */
export const GET_ALL_PERSPECTIVE = `${UserManagement}/StrategicObjective/getallperspective`;
export const SO_LINKED_OJB_KR = `${OkrManagement}/StrategicObjective/SearchSoLinked`;
export const GET_ALL_SO_OKR_LIST = `${OkrManagement}/StrategicObjective/SoDetails`;
export const GET_ALL_SO_TIME_FRAME = `${UserManagement}/StrategicObjective/getalltimeframe`;
export const SO_OKR_CREATE_DELETE_UPDATE = `${OkrManagement}/StrategicObjective/SoDetail`;
export const GET_SO_LINKED_OKR_LIST = `${OkrManagement}/StrategicObjective/LinkedOkrs`;
export const DELETE_SO_TIME_FRAME = `${OkrManagement}/StrategicObjective/soTimeframe`;
export const GET_ALL_SO_PERMISSION = `${UserManagement}/StrategicObjective/AdminSoPermission`;
export const GET_CYCLE_DETAIL_FOR_SO = `${UserManagement}/StrategicObjective/gettimeframecycle`;
export const UPDATE_SO_PROGRESS = `${OkrManagement}/StrategicObjective/ScoreManual`;
/**
 * SO Admin Related API Endpoints
 */
export const ADMIN_SO_MISSION_VISION = `${UserManagement}/StrategicObjective/missionvision`;
export const ADMIN_SO_MISSION_VISION_HISTORY = `${UserManagement}/StrategicObjective/getallmissionvision`;
export const ADMIN_SO_GET_MISSION_VISION_DETAILS = `${UserManagement}/StrategicObjective/landingmissionvision`;
export const ADMIN_SO_SETTINGS = `${UserManagement}/StrategicObjective/settings`;
export const ADMIN_SO_PERSPECTIVE = `${UserManagement}/StrategicObjective/perspective`;
export const IS_ADMIN_SO_PERSPECTIVE = `${UserManagement}/StrategicObjective/isperspective`;
export const SO_VISIBILITY_USERS = `${UserManagement}/StrategicObjective/sovisibilityUsers`;
export const SO_EDIT_TIME_FRAME = `${OkrManagement}/StrategicObjective/EditTimeFrame`;
export const SO_PROGRESS_VISIBILITY_USERS = `${UserManagement}/StrategicObjective/progressvisibilityusers`;

/**
 * Landing Mission Vision
 */
export const GET_MISSION_VISION = `${UserManagement}/StrategicObjective/landingmissionvision`;
//Aliognment map
export const GET_ALIGNMENT_LIST = `${OkrManagement}/Alignment/AlignmentView`;
export const GET_ALL_ALIGNMENT_LIST = `${OkrManagement}/Alignment/AllKrList`;
export const GET_ALIGNMENT_RECENT_SEARCH_DIRECT_REPORTER_LIST = `${OkrManagement}/Alignment/AlignmentReportees`;

//AI
export const GET_RECOMMENDATION_FILTER = `${OkrManagement}/MyGoals/recommendationFilter`;
export const AI_OKR_LIST = `${OkrManagement}/Recommendation/objectiverecommendation`;
export const GENERATE_MORE_OKR = `${OkrManagement}/Recommendation/GenerateMoreOkr`;
export const GENERATE_MORE_KR = `${OkrManagement}/Recommendation/GenerateMorekr`;
export const AI_ADD_OBJECTIVE = `${OkrManagement}/MyGoals/addobjective`;
export const AI_ADD_KEYRESULT = `${OkrManagement}/MyGoals/addkeyresult`;
export const AI_KR_LIST = `${OkrManagement}/Recommendation/keyresultrecommendation`;

/**
 * New Global Task API Endpoint Start Here
 */
export const GET_RECENT_ASSIGNEE = `${OkrTask}/task/GetRecentAssignee`;

/**
 * Default OKR API endpoints
 */
export const DEFAULT_OKR_CYCLE_DETAILS = `${OkrUserManagement}/cycles/DefaultOkrCycleDetails`;
export const DEFAULT_OKR_DESIGNATION_DETAILS = `${OkrUserManagement}/users/Designation`;
export const DEFAULT_OKR_PERFORMANCE_ROLE_DETAILS = `${OkrUserManagement}/users/PerformanceRole`;
export const CREATE_DEFAULT_OKR = `${OkrManagement}/DefaultOkr`;
// export const EDIT_DEFAULT_OKR = `${OkrManagement}/DefaultOkr/Edit`;
export const LIST_DEFAULT_OKR = `${OkrManagement}/DefaultOkr/List`;
export const COPY_DEFAULT_OKR = `${OkrManagement}/DefaultOkr/CopyDefaultOkr`;
export const COPY_OKR = `${OkrManagement}/DefaultOkr/copyOkr`;
export const CLONE_OKR = `${OkrManagement}/DefaultOkr/CloneOKR`;
export const PUBLISH_LIST = `${OkrManagement}/DefaultOkr/PublishOkr`;
export const DOWNLOAD_DEFAULT_OKR = `${FEEDBACK_BASE_URL}/Export/DefaultOkrReport`;
export const REPUBLISH_LIST = `${OkrManagement}/DefaultOkr/RePublishOkr`;
export const VALIDATE_BULK_DEFAULT_OKR = `${FEEDBACK_BASE_URL}/Export/DefaultOkrBulkValidate`;
export const UPLOAD_BULK_DEFAULT_OKR = `${FEEDBACK_BASE_URL}/Export/DefaultOkrBulkUpload`;
/**
 * User Assessment Form API endpoints
 */
export const GET_SELF_ASSESSMENT = `${UserManagement}/Assessment/GetAssessment`;
export const GET_EMPLOYEE_ASSESSMENT = `${UserManagement}/Assessment/GetAssessment`;
export const SAVE_EMPLOYEE_ASSESSMENT = `${UserManagement}/Assessment/SaveAssessment`;
export const SAVE_MULTIPLE_EMPLOYEE_ASSESSMENT = `${UserManagement}/Assessment/SaveMultipleAssessment`;
export const GET_ASSESSMENT_DATA = `${UserManagement}/Assessment/PerformanceSummeryData`;

/*
 * User Letter Gen API Endpoints
 */

export const LG_CREATE_PERMISSION = `${UserManagement}/LG/LgCreatePermission`;
export const LG_REMOVE_PERMISSION = `${UserManagement}/LG/LgRemovePermission`;
export const LG_PERMISSION_SETTINGS = `${UserManagement}/LG/LgPermissionSettings`;
export const GET_TEMPLATE_LIST = `${TemplateController}/TemplateController/GetTemplateList`;
export const ADD_COLUMN_IN_TEMPLATE = `${TemplateController}/TemplateController/AddTemplateColumn`;
export const GET_TEMPLATE_COLUMN_LIST_BY_TEMPLATE_ID = `${TemplateController}/TemplateController/GetTemplateColumnListByTemplateId`;
export const GENERATE_SAMPLE_TEMPLATE_BY_TEMPLATE_ID = `${TemplateController}/TemplateController/GenerateSampleTemplateByTemplateId`;
export const ADD_NEW_TEMPLATE = `${TemplateController}/TemplateController/ADD_NEW_TEMPLATE`;
export const UPLOAD_TEMPLATE_DATA = `${TemplateController}/TemplateController/UploadTemplateData`;
export const GET_UPLOADED_LETTER_INFORMATION_BY_PARAMETERS = `${TemplateController}/TemplateController/GetUploadedLetterInformationByParameters`;
export const DELETE_LETTER_INFORMATION = `${TemplateController}/TemplateController/DeleteLetterInformation`;
export const SEND_LETTER_EMAIL = `${TemplateController}/TemplateController/SendLetterEmail`;
export const GENERATE_LETTER = `${TemplateController}/TemplateController/GenerateLetter`;
export const PREVIEW_LETTER = `${TemplateController}/TemplateController/PreviewLetter`;
export const VALIDATE_LETTER = `${TemplateController}/TemplateController/validateLetter`;
export const SCHEDULE_LETTER_EMAIL = `${TemplateController}/TemplateController/ScheduleLetterEmail`;
export const DOWNLOAD_ZIP = `${TemplateController}/TemplateController/DownloadZip`;

/*
 * User Snapshot API Endpoints
 */
export const PERFORMANCE_SNAPSHOT_ENGAGEMENT = `${UserManagement}/PerformanceSnapshot/Engagement`;
export const PERFORMANCE_SNAPSHOT_FEEDBACK_DETAILS = `${UserManagement}/PerformanceSnapshot/PerformanceSnapshotDetails`;
export const PERFORMANCE_SNAPSHOT_PROCESS = `${UserManagement}/PerformanceSnapshot/PerformanceSnapshotScore`;

/**
 * User PIP API Endpoint
 */
export const DELETE_UPLOADED_CONVERSATION = `${okrConversation}/conversation/DeleteStorageFile`;
export const PIP_NOMINEE_SEARCH = `${OkrUserManagement}/users/NomineeSearch`;
export const PIP_CREATE_PIP_PLAN = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan`;
export const PIP_LISTING = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan`;
export const PIP_MY_LISTING = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan/GetMyPerformanceImprovementPlanList`;
export const PIP_OTHERS_LISTING = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan/GetOtherPerformanceImprovementPlanList`;
export const PIP_VIEW_PIP_PLAN = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan/Details`;
export const PIP_SAVE_COMMENT = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan/Comments`;
export const PIP_DELETE_COMMENT = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan/DeleteComments`;
export const PIP_GET_COMMENT = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan/GetComments`;
export const PIP_GET_REVIEWER_DATE = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan/ReviewersDate`;
export const PIP_RESOLVE_STATUS = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan/ResolveStatus`;
export const PIP_RESOLVE_PLAN_STATUS = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlanStatus`;
export const PIP_PLAN_REASON = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlanReason`;
export const FEEDBACK_PLAN_REASON = `${FEEDBACK_BASE_URL}/FeedbackReason`;
export const PIP_CHECK_NOMINEE_EXISTS = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan/IsNomineeExists`;
export const PIP_ALERT = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlan/RedDotAlert`;

// Feedback Tab API endpoints
export const NOMINEE_FEEDBACK_LIST = `${FEEDBACK_BASE_URL}/EmployeeFeedback/Get`;
export const FEEDBACK_MY_LISTING = `${FEEDBACK_BASE_URL}/EmployeeFeedback/GetMyFeedbackList`;
export const FEEDBACK_VIEW_PIP_PLAN = `${FEEDBACK_BASE_URL}/EmployeeFeedback/Details`;
export const FEEDBACK_OTHERS_LISTING = `${FEEDBACK_BASE_URL}/EmployeeFeedback/GetOtherFeedbackList`;
export const FEEDBACK_CATEGORY = `${FEEDBACK_BASE_URL}/FeedbackCategory`;
export const FEEDBACK_STATUS = `${FEEDBACK_BASE_URL}/FeedbackStatus`;
export const FEEDBACK_TYPE = `${FEEDBACK_BASE_URL}/FeedbackType`;
export const POST_FEEDBACK = `${FEEDBACK_BASE_URL}/EmployeeFeedback`;

export const FEEDBACK_RESOLVE_STATUS = `${FEEDBACK_BASE_URL}/EmployeeFeedback/ResolveStatus`;
export const FEEDBACK_CREATE_FEEDBACK_PLAN = `${FEEDBACK_BASE_URL}/EmployeeFeedback`;
export const FEEDBACK_ALERT = `${FEEDBACK_BASE_URL}/EmployeeFeedback/FeedbackAlert`;

// Admin Performance Development API endpoints
export const PERFORMANCE_IMPROVEMENT_PLAN_EMAILRECIPIENT = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlanSetting/GetEmailReceipient`;
export const PERFORMANCE_IMPROVEMENT_PLAN_UPDATE_EMAILRECIPIENT = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlanSetting/UpdateEmailReceipient`;
export const PERFORMANCE_IMPROVEMENT_PLAN_SETTING = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlanSetting`;
export const PERFORMANCE_IMPROVEMENT_PLAN_EMAIL_CONF = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlanSetting/GetEmailSetting`;
export const PERFORMANCE_IMPROVEMENT_PLAN_UPDATE_EMAIL_CONF = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlanSetting/UpdateEmailSetting`;
// export const PERFORMANCE_IMPROVEMENT_PLAN_STATUS = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlanStatus`;
export const PERFORMANCE_IMPROVEMENT_PLAN_STATUS_ENABLE = `${FEEDBACK_BASE_URL}/PerformanceImprovementPlanStatus/Enable`;

export const FEEDBACK_SETTING = `${FEEDBACK_BASE_URL}/FeedbackSetting`;
// export const FEEDBACK_STATUS = `${FEEDBACK_BASE_URL}/FeedbackStatus`;
export const FEEDBACK_STATUS_ENABLE = `${FEEDBACK_BASE_URL}/FeedbackStatus/Enable`;

export const FEEDBACK_TYPE_ENABLE = `${FEEDBACK_TYPE}/Enable`;
export const FEEDBACK_CATEGORY_ENABLE = `${FEEDBACK_CATEGORY}/Enable`;

// download reports
export const PIP_REPORT = `${FEEDBACK_BASE_URL}/Export/PerformanceImprovementPlanReport`;
export const UPDATEDFEEDBACKREPORTS = `${FEEDBACK_BASE_URL}/Export/FeedBackReport`;

export const FEEDBACK_ONETOONE_SETTINGS = `${FEEDBACK_BASE_URL}/OneToOneSetting`;
export const PERFORMACE_GOAL_FORM = `${UserManagement}/PerformanceGoalForm`;
export const PERFORMACE_RESPONSE_TYPE = `${UserManagement}/PerformanceGoalForm/ResponseType`;
export const GOAL_FORM_DOWNLOAD = `${FEEDBACK_BASE_URL}/Export/PerformanceGoalFormAdminReport`;
export const GOAL_CYCLE_DOWNLOAD = `${FEEDBACK_BASE_URL}/Export/PerformanceGoalCycleReport`;
export const WORKFLOW_REVERSAL_DOWNLOAD = `${FEEDBACK_BASE_URL}/Export/GoalSettingReversalReport`;
export const EACH_GOAL_FORM_DOWNLOAD = `${FEEDBACK_BASE_URL}/Export/PerformanceGoalFormByIdReport`;
export const PERFORMANCE_GOAL_FORM_DELETE_REPORT = `${FEEDBACK_BASE_URL}/Export/PerformanceGoalFormDeleteReport`;
export const EMPLOYEE_STATUS_REPORT = `${FEEDBACK_BASE_URL}/Export/EmployeeStatusReport`;
export const PERFORMANCE_GOAL_SETTING_REVERSAL_REPORT = `${FEEDBACK_BASE_URL}/Export/GoalSettingReversalReport`;

// Performance category and goals list API endpoints
export const PERFORMANCE_GOAL_LIST = `${UserManagement}/PerformanceGoalSet/GetGoalSetListing`;
export const GOAL_CYCLE_SELECTOR = `${UserManagement}/PerformanceGoalCycle/Cycle`;
export const PERFORMANCE_GOAL_SAVE = `${UserManagement}/PerformanceGoalSet`;
export const PERFORMANCE_GOAL_DOCUMENT = `${UserManagement}/User/Documents`;
export const SAVE_GOAL_COMMENT = `${okrConversation}/Recognition/PerformanceGoalComments`;
export const LIKE_CONVERSATION_GOAL = `${okrConversation}/conversation/PerformanceGoalLike`;
export const GET_CONVERSATION_GOAL = `${okrConversation}/recognition/Comments`;
export const PERFORMANCE_GOAL_RESPONSE_LIST = `${UserManagement}/PerformanceGoalSet/ResponseLog`;

// Performance Team details API endpoints
export const PERFORMANCE_GOAL_TEAM_DETAILS = `${UserManagement}/PerformanceGoalSet/GetDrListing`;
export const GET_GOALS_CYCLE_RECORD_LIST = `${UserManagement}/PerformanceGoalCycle/PerformanceCycleList`;

export const SAVE_PERFORMANCE_WORKFLOW = `${UserManagement}/PerformanceGoalWorkflow`;
export const SAVE_PERFORMANCE_WORKFLOW_CURRENT_STAGE = `${UserManagement}/PerformanceGoalWorkflow/WorkflowCurrentStage`;
export const SEARCH_ONGOING_EMPLOYEE = `${UserManagement}/PerformanceGoalWorkflow/SearchEmployeeOngoing`;
export const GOAL_SETTING_WORKFLOW_REVERSAL_CYCLE_LIST = `${UserManagement}/PerformanceGoalWorkflow/WorkflowCycleList`;

export const GOAL_CONVERSATION_ALERT = `${UserManagement}/PerformanceGoalSet/RedDotMenu`;
export const DEFAULT_CC_USER = `${UserManagement}/User/DefaultReviewers`;
export const FEEDBACK_AVAILABLE_FOR_ONETOONE = `${FEEDBACK_BASE_URL}/OneToOne/IsInitiateMeetingPermissionAvailable`;
export const FEEDBACK_SEARCH_FOR_ONETOONE = `${FEEDBACK_BASE_URL}/OneToOne/OneToOneEmployeeSearch`;
export const GOAL_REVIEW_SAVE = `${UserManagement}/PerformanceGoalRatingAssessment`;
export const TEAM_REVIEW_USER_LIST = `${UserManagement}/PerformanceGoalRatingAssessment/TeamsReviewList`;
export const FEEDBACK_PERMISSION_SEARCH = `${FEEDBACK_BASE_URL}/EmployeeFeedback/FeedbackEmployeeSearch`;
export const FEEDBACK_PERMISSION = `${FEEDBACK_BASE_URL}/FeedbackSetting/EmployeeFeedbackPermission`;

//Ai Bot
export const aiBotOKRDataReader = `${aiBotOKRBaseUrl}/okr_data_reader`;
export const aiBotLogin = `${aiBotBaseUrl}/aiauthor${ubotApiEndpointSuffix}/identity/login`;

//Permission visibility

export const PERMISSION_SEARCH = `${UserManagement}/AdditionalPermission/Search`;
export const PERMISSION_FEEDBACK_SAVE = `${UserManagement}/AdditionalPermission`;
export const GET_SYSTEM_PERMISSION_DATA = `${UserManagement}/AdditionalPermission/GetModulePermission`;
export const PERMISSION_CATEGORY_SEARCH = `${UserManagement}/AdditionalPermission/FeedbackCategorySearch`;
